import { Component, HostBinding, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationStateService } from 'src/app/state/organization.state.service';

@Component({
  selector: 'app-notifications-badge',
  templateUrl: './notifications-badge.component.html',
  styleUrls: ['./notifications-badge.component.scss']
})
export class NotificationsBadgeComponent implements OnInit {

  totalNotifications$ = this.$organizationState.getNotificationsEvents.getResponse$().pipe(
    map(notifications => (notifications ?? []).length)
  );

  totalNotificationView$: Observable<string> = this.totalNotifications$.pipe(
    map(totalNotifications => {
      if (totalNotifications > 9999) {
        return '9999+';
      }
      return totalNotifications.toString();
    })
  );

  largeNumber$ = this.totalNotifications$.pipe(
    map(totalNotifications => totalNotifications > 9999)
  );

  subscription = this.largeNumber$.subscribe(largeNumber => {
    this.hasLargeNumber = largeNumber;
  })

  @HostBinding('class.large-number')
  hasLargeNumber: boolean;

  constructor(private $organizationState: OrganizationStateService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
