import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import InjectorWrapper from './app/decorators/injector-wrapper';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";

Sentry.init({
  dsn: "https://4847a71ce6c946c19c0deac2316e6f72@o255899.ingest.sentry.io/6159170",
  environment: environment.production ? 'production' : 'development',
  integrations: [
    new BrowserTracing({
      tracingOrigins: ["localhost", environment.API_ENDPOINT],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new CaptureConsole({
      levels: ["error"]
    }),
  ],


  // @see https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/
  tracePropagationTargets: ["localhost", /^https:\/\/me-fit-backend-gwagq.ondigitalocean.app/],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  beforeSend(event) {
    // Temp solution until we buy ag-grid enterprise
    const agGridErrorMessage = event.message?.startsWith('*');

    if (agGridErrorMessage) {
      return null;
    }

    return event;
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(c => InjectorWrapper.setInjector(c.injector))
  .catch(err => console.log(err));
