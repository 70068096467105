import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { StateManagerService } from "./state-manager.service";
import { CalendarEvent, CalendarEventCreateRequest, FindAllWithinRangeRequest, CalendarEventUpdateRequest } from '@me-fit-mono/typings';


@Injectable({
  providedIn: "root"
})
export class CalendarsStateService {
  private API_CALENDARS = `${environment.API_ENDPOINT}/calendars`;

  findAllWithinRange = this.$state.createState<FindAllWithinRangeRequest, Array<CalendarEvent>>({
    httpMethod: "post",
    path: `${this.API_CALENDARS}/within-range`,
  });

  create = this.$state.createState<CalendarEventCreateRequest, CalendarEvent[]>({
    httpMethod: "post",
    path: this.API_CALENDARS
  });

  update = this.$state.createState<CalendarEventUpdateRequest, CalendarEvent, { calendarEventId: string }>({
    httpMethod: "patch",
    path: `${this.API_CALENDARS}/:calendarEventId`
  });

  delete = this.$state.createState<void, CalendarEvent, { calendarEventId: string }>({
    httpMethod: "delete",
    path: `${this.API_CALENDARS}/:calendarEventId`
  });


  constructor(private $state: StateManagerService) { }

  getEndDateForAllDayEvent(startDate: Date|string) {
    const endDate = typeof startDate == 'string' ? new Date(startDate) : startDate;
    endDate.setUTCHours(23, 59, 59, 999);

    return endDate;
  }
}
