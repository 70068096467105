import { Injectable } from "@angular/core";
import { CreateTagRequest, CreateTagResponse, ListTagsResponse, OrganizationTagType, UpdateTagRequest, UpdateTagResponse } from "@me-fit-mono/typings";
import { environment } from "src/environments/environment";
import { StateManagerService } from "./state-manager.service";
import { map } from "rxjs";


@Injectable({
  providedIn: "root"
})
export class TagsStateService {
  private API_TAGS = `${environment.API_ENDPOINT}/tags`;

  create = this.$state.createState<CreateTagRequest, CreateTagResponse>({
    httpMethod: 'post',
    path: `${this.API_TAGS}`,
    onNewResponse: () => this.list.call()
  });

  list = this.$state.createState<void, ListTagsResponse>({
    httpMethod: 'get',
    path: `${this.API_TAGS}`,
  });

  update = this.$state.createState<UpdateTagRequest, UpdateTagResponse, {id: string}>({
    httpMethod: 'put',
    path: `${this.API_TAGS}/:id`,
    onNewResponse: () => this.list.call()
  });

  delete = this.$state.createState<void, unknown, {id: string}>({
    httpMethod: 'delete',
    path: `${this.API_TAGS}/:id`,
    onNewResponse: () => this.list.call()
  });


  constructor(private $state: StateManagerService) { }
}
