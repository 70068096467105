import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { isEqual } from 'lodash-es';
import { ME_Fit } from 'me-fit-typings';
import { fadeInOut, slideUpDown } from 'src/app/animations';
import { GroupsStateService } from 'src/app/state/groups.state.service';
import { ColorVariables } from '../profile-image/profile-image.component';

type GroupState = 'loading' | 'loaded' | 'failed';

export interface ViewGroup extends ME_Fit.OrganizationGroup {
  color?: ColorVariables;
  /** key is customerIds value is workoutIds */
  organizationCustomerOverrides?: Map<string, string>;
}

@Component({
  selector: 'me-calendar-group-list',
  templateUrl: './calendar-group-list.component.html',
  styleUrls: ['./calendar-group-list.component.scss'],
  animations: [fadeInOut, slideUpDown]
})
export class CalendarGroupListComponent implements OnInit {

  private _groups: ViewGroup[] = [];


  public get groups(): ViewGroup[] {
    return this._groups;
  }

  @Input()
  public set groups(value: ViewGroup[]) {
    if (!isEqual(this._groups, value)) {
      this._groups = value;
    }

    this.fetchGroupCustomers();


  }

  @Input() showRemoveButton = true;

  @Input() showGroupExpandButton = true;

  @Input() showNavigationButton = false;

  /** will expand all groups when the component is initalised */
  @Input() expandAllGroups = false;

  @Output() onGroupRemove = new EventEmitter<string>();

  /**
   * Not to be confused with opening a group to view its members
   * this is for when users want the group as indiviual items
   */
  @Output() onGroupExpand = new EventEmitter<ViewGroup>();

  @Output() onNavigateToGroup = new EventEmitter<string>();

  @Output() onNavigateToWorkout = new EventEmitter<string>();

  openedGroups = new Set();

  groupStateMap: { [key: string]: GroupState } = {};

  constructor(private $groupState: GroupsStateService) { }

  ngOnInit() {
  }

  removeGroup(groupId: string, event: Event) {
    event.stopPropagation();

    this.onGroupRemove.emit(groupId);

    delete this.groupStateMap[groupId];
  }

  expandGroupOut(groupId: string, event: Event) {
    event.stopPropagation();

    const selectedGroup = this.groups.find(group => group.id === groupId);

    this.onGroupExpand.emit(selectedGroup);

    delete this.groupStateMap[groupId];
  }

  navigateToGroup(groupId: string, event: Event) {
    event.stopPropagation();

    this.onNavigateToGroup.emit(groupId);
  }

  navigateToWorkout(workoutId: string, event: Event) {
    event.stopPropagation();

    this.onNavigateToWorkout.emit(workoutId);
  }

  toggleGroupOpenState(groupId: string) {
    if (this.openedGroups.has(groupId)) {
      this.openedGroups.delete(groupId);
    } else {
      this.openedGroups.add(groupId);
    }
  }

  async fetchGroupCustomers() {
    /**
     * By default. Searched groups will not contain customer data to keep it fast
     * so once a group is selected, we need to pre-fetch the customers for that group
     */
    for (const group of this.groups) {
      // Skip one iteration if the group already has customers
      if ('customers' in group) {
        this.groupStateMap[group.id] = 'loaded';

        continue;
      }

      try {
        await this.loadGroup(group.id);
      } catch { }
    }

    if (this.expandAllGroups) {
      const expandableGroups = this.groups.filter(group => 'customers' in group);
      this.openedGroups = new Set(expandableGroups.map(group => group.id));
    }
  }

  async loadGroup(groupId: string) {
    try {
      this.groupStateMap[groupId] = 'loading';

      const groupResponse = await this.$groupState.get.call({
        pathParts: { groupId: groupId }
      });

      const matchingGroup = this.groups.find(group => group.id === groupId);

      matchingGroup!.customers = groupResponse.customers;

      this.groupStateMap[groupId] = 'loaded';
    } catch (error) {
      console.error('[CalendarGroupListComponent] error fetching group customers', error);

      this.groupStateMap[groupId] = 'failed';
    }
  }

}
