import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash-es';

@Pipe({
  name: 'isEmpty',
  pure: true
})
export class IsEmptyPipe implements PipeTransform {
  constructor() {}

  transform(array: any): boolean {
    return isEmpty(array);
  }

}
