import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private $auth: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, route: RouterStateSnapshot) {
    return this.$auth.getLoggedInState().pipe(
      tap(loggedIn => {
        if (!loggedIn) {
          console.log(`[AuthGuardService] loggedIn is false, navigating to login`);
          this.router.navigate(['login']);
          this.$auth.postLoginRedirectUrl = route.url;
        }
      }),
    );
  }
}
