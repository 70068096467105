export const environment = {
  firebase: {
    projectId: 'me-fit-d7a18',
    appId: '1:34365193355:web:dbee969f16946087dd688e',
    databaseURL: 'https://me-fit-d7a18.firebaseio.com',
    storageBucket: 'me-fit-d7a18.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyBmQBjtSabOVQBicY8Mkv4kwIUkmc30Y74',
    authDomain: 'admin.mefit.pro',
    messagingSenderId: '34365193355',
    measurementId: 'G-SV8EJ889FJ',
  },
  production: true,
  API_ENDPOINT: 'https://me-fit-backend-gwagq.ondigitalocean.app/api',
  firebaseRemoteConfig: {
    minimumFetchIntervalMillis: 43200000, // 12 hours
    defaultConfig: {
      'homeScreenSettingsOrgIds': "[]"
    }
  }
};
