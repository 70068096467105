import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { StateManagerService } from "./state-manager.service";
import { OnboardingInitiateRequest, OnboardingInitiateResponse, OnboardingStatusResponse } from "@me-fit-mono/typings";


@Injectable({
  providedIn: "root"
})
export class OnboardingStateService {
  private API = `${environment.API_ENDPOINT}/onboarding`;

  initiate = this.$state.createState<OnboardingInitiateRequest, OnboardingInitiateResponse>({
    httpMethod: 'post',
    path: `${this.API}/initiate`,
  });

  status = this.$state.createState<void, OnboardingStatusResponse>({
    httpMethod: 'get',
    path: `${this.API}/status`,
  });

  constructor(private $state: StateManagerService) { }
}
