import { NgModule } from '@angular/core';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { LoginGuardService } from './login-guard.service';
import { OnboardingStartPage } from '../pages/onboarding/onboarding-start/onboarding-start.page';
import { OnboardingStatusPage } from '../pages/onboarding/onboarding-status/onboarding-status.page';
import { OnboardingModule } from '../pages/onboarding/onboarding.module';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../pages/login/login.module').then(m => m.LoginModule),
    canActivate: [LoginGuardService],
    data: {
      hideSidebar: true
    },
  },
  {
    path: 'onboarding',
    component: OnboardingStartPage,
    data: {
      hideSidebar: true
    }
  },
  {
    path: 'employee/invite/:inviteId',
    loadChildren: () => import('../pages/employee/employee-invite/employee-invite.module').then(m => m.EmployeeInvitePageModule),
    canActivate: [LoginGuardService],
    data: {
      hideSidebar: true
    }
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'workouts'
      },
      {
        path: 'workouts',
        loadChildren: () => import('../pages/workout/workout.module').then(m => m.WorkoutModule)
      },
      {
        path: 'employees',
        loadChildren: () => import('../pages/employee/employee.module').then(m => m.EmployeeModule)
      },
      {
        path: 'exercises',
        loadChildren: () => import('../pages/exercise/exercise.module').then(m => m.ExerciseModule)
      },
      {
        path: 'customers',
        loadChildren: () => import('../pages/customer/customer.module').then(m => m.CustomereModule)
      },
      {
        path: 'groups',
        loadChildren: () => import('../pages/group/group.module').then(m => m.GroupModule)
      },
      {
        path: 'home-screen-settings',
        loadChildren: () => import('../pages/home-screen-settings/home-screen-settings.module').then(m => m.HomeScreenSettingsPageModule)
      },
      {
        path: 'chat',
        loadChildren: () => import('../pages/chat/chat.module').then(m => m.ChatModule)
      },
      {
        path: 'calendar',
        loadChildren: () => import('../pages/calendar/calendar.module').then(m => m.CalendarModule)
      },
      {
        path: 'organization',
        loadChildren: () => import('../pages/organization/organization.module').then(m => m.OrganizationModule)
      },
      {
        path: 'onboarding/success',
        component: OnboardingStatusPage,
      },
      {
        path: 'notifications',
        loadChildren: () => import('../pages/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'training-session',
        loadChildren: () => import('../pages/training-session/training-session.module').then(m => m.TrainingSessionModule)
      }
    ]
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    provideAuth(() => getAuth()),
    OnboardingModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
