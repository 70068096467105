<ion-header>
  <ion-toolbar>
    <ion-title>
      <ion-row class="ion-justify-content-center ion-align-items-center ion-padding">
        <app-logo class="logo"></app-logo>
      </ion-row>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <main>
    <ion-row>
      <ion-col size="4" size-lg="4" class="ion-hide-md-down ion-no-padding">
        <div class="ion-padding border-bottom">
          <h5 class="ion-margin-start">{{ 'onboarding.features.header' | translate }}</h5>

          <ul class="ion-margin-top ion-padding-start">
            <li><small>{{ 'onboarding.features.bullet.one'  | translate }}</small></li>
            <li><small>{{ 'onboarding.features.bullet.two'  | translate }}</small></li>
            <li><small>{{ 'onboarding.features.bullet.three' | translate  }}</small></li>
            <li><small>{{ 'onboarding.features.bullet.four' | translate  }}</small></li>
            <li><small>{{ 'onboarding.features.bullet.five' | translate  }}</small></li>
          </ul>
        </div>
      </ion-col>
      <ion-col size="12" size-md="6" size-lg="6" class="register-form-column border-left">
        <h2>{{ 'onboarding.page.title' | translate }}</h2>
        <form [formGroup]="form">
          <ion-item fill="outline">
            <ion-label position="floating">{{ 'first.name' | translate }}</ion-label>
            <ion-input autocomplete="given-name" type="text" formControlName="firstName"></ion-input>
          </ion-item>
          <ion-item fill="outline" class="ion-margin-top">
            <ion-label position="floating">{{ 'last.name' | translate }}</ion-label>
            <ion-input autocomplete="family-name" type="text" formControlName="lastName"></ion-input>
          </ion-item>
          <ion-item fill="outline" class="ion-margin-top">
            <ion-label position="floating">{{ 'organization.name' | translate }}</ion-label>
            <ion-input type="text" formControlName="organizationName"></ion-input>
          </ion-item>
          <ion-item fill="outline" class="ion-margin-top" lines="none" [class.has-error]="form.get('organizationType')!.touched && form.get('organizationType')!.hasError('required')">
            <ion-label position="stacked">
              {{'organization.type' | translate}}
            </ion-label>
            <ion-select interface="popover" formControlName="organizationType" placeholder="-" [interfaceOptions]="organizationTypeSelectPopoverOpts">
              <ion-select-option *ngFor="let organizationTypeOption of organizationTypeOptions" [value]="organizationTypeOption.value">
                {{ organizationTypeOption.label$ | async }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item lines="none"
            [class.has-error]="form.get('termsAndConditions')!.touched && form.get('termsAndConditions')!.hasError('required')"
            class="ion-margin-top" fill="outline">
            <ion-checkbox slot="end" formControlName="termsAndConditions"></ion-checkbox>
            <ion-label>{{ 'terms.and.conditions' | translate }}</ion-label>
          </ion-item>
          <ion-note [innerHTML]="'terms.and.conditions.message' | translate: { link: termsAndConditions }">
          </ion-note>
          <ion-item lines="none" [class.has-error]=" form.get('privacyPolicy')!.touched &&
                  form.get('privacyPolicy')!.hasError('required')" class="ion-margin-top" fill="outline">
            <ion-checkbox slot="end" formControlName="privacyPolicy"></ion-checkbox>
            <ion-label>{{ 'privacy.policy' | translate }}</ion-label>
          </ion-item>
          <ion-note [innerHTML]="'privacy.policy.message' | translate: { link: privacyPolicy }">
          </ion-note>

          <!-- <me-google-sign-in-button class="ion-hide-md-down" (click)="signUpWithGoogle()"
            text="Sign up with Google"></me-google-sign-in-button> -->
        </form>
      </ion-col>
    </ion-row>
  </main>
</ion-content>
<ion-footer class="ion-padding-vertical border-top">
  <ion-row>
    <ion-col size="4" size-lg="4" class="ion-hide-md-down ion-no-padding"></ion-col>
    <ion-col size="12" size-md="6" size-lg="6" class="footer-right-column">
      <me-google-sign-in-button (click)="signUpWithGoogle()" text="Sign up with Google"></me-google-sign-in-button>
    </ion-col>
  </ion-row>
</ion-footer>
