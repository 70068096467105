import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { OrganizationNotification } from '@me-fit-mono/typings';
import { map } from 'rxjs/operators';
import { OrganizationStateService } from 'src/app/state/organization.state.service';

export interface NotificationPopoverDismissEventData {
  organizationNotification?: OrganizationNotification;
  showMore?: boolean;
}

@Component({
  selector: 'app-notification-popover',
  templateUrl: './notifications-popover.component.html',
})
export class NotificationsPopoverComponent implements OnInit {

  maxItemsToShow = 5;

  notifications$ = this.$organizationState.getNotificationsEvents.getResponse$();

  notificationsToShow$ = this.notifications$.pipe(
    map((notifications) => (notifications ?? []).slice(0, this.maxItemsToShow))
  );

  showShowMoreButton$ = this.notifications$.pipe(
    map((notifications) => (notifications ?? []).length)
  )

  constructor(private $organizationState: OrganizationStateService, private popoverCtrl: PopoverController) { }

  ngOnInit() {
  }

  openNotification(event: OrganizationNotification) {
    this.popoverCtrl.dismiss({
      organizationNotification: event,
    } as NotificationPopoverDismissEventData);
  }

  showMore() {
    this.popoverCtrl.dismiss({
      showMore: true,
    } as NotificationPopoverDismissEventData)
  }
}
