<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-toggle>
        <ion-button>
          <ion-icon name="menu"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
    <ion-title>
      <ion-row class="ion-align-items-center">
        {{ 'onboarding.status.button' | translate }}
        <ion-spinner class="margin-start-auto" *ngIf="!(hasResponse$ | async)" @fadeInOut></ion-spinner>
      </ion-row>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <app-logo class="logo" @fadeInOut></app-logo>

  <h3>{{ 'onboarding.status.title' | translate }}</h3>
  <p>
    {{ 'onboarding.status.message' | translate }}
  </p>
  <ion-list *ngIf="hasResponse$ | async" @fadeInOut>
    <ion-item *ngFor="let item of onboardingCheckListItems$ | async; let index = index" lines="full" class="check-list-item" [class.done]="item.done">
      <ion-label>
        <div class="title">{{ item.title | async }}</div>
        <small class="ion-text-wrap">
          {{item.message | async}}
        </small>

        <ion-button class="call-to-action" *ngIf="item.button" (click)="item.button?.onClick()">
          {{ item.button.text | async }}
        </ion-button>
      </ion-label>
      <ion-icon name="checkmark-circle-outline" slot="start" color="success"></ion-icon>
      <div slot="start" class="circle">{{index + 1}}</div>
    </ion-item>
  </ion-list>
</ion-content>

<ion-modal #accordionsModal>
  <ng-template>

    <ion-header>
      <ion-toolbar>
        <ion-title> {{ activeAccordionModalData.entryData.modalTitle | async }} </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="accordionsModal.dismiss()">
            <ion-icon name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding-horizontal ion-padding-top">
      {{activeAccordionModalData.entryData.modalMessage | async}}

      <ion-accordion-group [value]="activeAccordionModalData.selectedAccordionId" class="ion-margin-top">
        <ion-accordion *ngFor="let workoutCreateAccordionEntry of activeAccordionModalData.entryData.accordions" [value]="workoutCreateAccordionEntry.id" [readonly]="true">
          <ion-item slot="header" color="light">
            <ion-label>{{ workoutCreateAccordionEntry.title | async }}</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ng-container *ngFor="let message of workoutCreateAccordionEntry.messages">
              {{ message | async }}
              <br> <br>
            </ng-container>
          </div>
        </ion-accordion>
      </ion-accordion-group>

    </ion-content>

    <ion-footer class="ion-padding buttons-container">
      <ion-row class="ion-justify-content-end">
        <ion-button fill="outline" class="ion-margin-end"
          (click)="workoutCreateModalAccordionNavigate(-1)">{{'action.back' | translate}}</ion-button>
        <ion-button fill="outline" (click)="workoutCreateModalAccordionNavigate(1)">{{'action.next' | translate}}</ion-button>
      </ion-row>
    </ion-footer>

  </ng-template>
</ion-modal>
