<svg [attr.width]="side" [attr.height]="side">
  <circle class="progress-ring__circle" stroke="rgba(255,255,255,0.12)" stroke-width="4" fill="transparent"
    [attr.r]="radius" [attr.cx]="side / 2" [attr.cy]="side / 2" [style.strokeDasharray]="strokeDasharray"
    [style.strokeDashoffset.px]="0" />
  <circle class="progress-ring__circle" stroke="var(--ion-color-secondary)" stroke-width="4" fill="transparent" [attr.r]="radius"
    [attr.cx]="side / 2" [attr.cy]="side / 2" [style.strokeDasharray]="strokeDasharray"
    [style.strokeDashoffset.px]="strokeDashoffset" />
  <text fill="white" x="50%" y="50%" text-anchor="middle" alignment-baseline="middle">
  {{text}}
  </text>

</svg>
