import { ActivatedRouteSnapshot, RouteReuseStrategy } from "@angular/router";
import { IonicRouteStrategy } from "@ionic/angular";

export default class CustomRouterReuseStrategy extends IonicRouteStrategy implements RouteReuseStrategy {
  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {

    const ionicShouldReuseRoute = super.shouldReuseRoute(future, curr);

    /**
     * We will use this to ensure that the details page is not reused
     * as it causes issues with the state of the page
     * example
     *
     * 1. Go to customer details with customer 1 page
     * 2. Refresh
     * 3. Go to customer list page
     * 4. Go to customer details with customer 2 page
     * 5. The page will show the details of customer 1
     */
    const isDetailsPage = future.params.id;
    const shouldReuseRoute = ionicShouldReuseRoute && !isDetailsPage;

    return shouldReuseRoute;
  }
}
