import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, getIdToken, user } from "@angular/fire/auth";
import { isNil } from 'lodash-es';
import { Observable, from } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { SentryService } from './sentry.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: Auth, private $auth: AuthService, private $sentry: SentryService) { }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only if this is an API request do we want to mutate the headers.
    //
    if (!httpRequest.url.includes(environment.API_ENDPOINT)) {
      return next.handle(httpRequest);
    }

    return user(this.auth).pipe(
      take(1),
      mergeMap( user => {
        if (!user) {
          return Promise.resolve(null);
        }

        const getIdTokenPromise = getIdToken(user).catch(error => {
          console.error('[auth:service] getIdToken error', error);
        });

        return from(getIdTokenPromise);
      }),
      mergeMap((token) => {
        let updatedHttpRequest = httpRequest;
        if (!isNil(token)) {
          updatedHttpRequest = httpRequest.clone({ setHeaders: { Authorization: token } })
        }

        const currentOrganizationId = this.$auth.getCurrentOrganizationId();

        if (currentOrganizationId) {
          updatedHttpRequest = updatedHttpRequest.clone({ setHeaders: { currentOrganizationId } })
        }

        return next.handle(updatedHttpRequest);
      })
    );
  }
}
