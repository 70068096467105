<ion-app>
  <ion-split-pane contentId="main" [disabled]="hideSidebar$ | async" [class.expand-side-menu]="expandSideMenu">
    <!--  the side menu  -->
    <ion-menu contentId="main" menuId="mainMenu" #mainMenu [disabled]="hideSidebar$ | async">
      <ion-header>
        <ion-toolbar>
          <ion-row class="ion-justify-content-between">
            <app-logo class="logo"></app-logo>
          </ion-row>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list class="menu-items">
          <ion-menu-toggle *ngFor="let menuItem of menuItems" autoHide="false">
            <ion-item class="menu-item" button lines="full" [routerLink]="menuItem.path" routerLinkActive="active"
              [hidden]="menuItem.hidden$ && menuItem.hidden$ | async">
              <ion-icon slot="start" [name]="menuItem.icon"></ion-icon>
              <ion-label>{{ menuItem.i18nLabel| translate }}</ion-label>
              <ion-badge color="secondary" *ngIf="menuItem.isNew">{{ 'new' | translate }}</ion-badge>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <ion-footer class="ion-no-padding">
        <me-onboarding-progress-menu-item (click)="closeMenu()"></me-onboarding-progress-menu-item>
        <ion-item *ngIf="!(hideNotificationsButton$ | async)" button lines="full"
          [disabled]="notificationsLoading$ | async" id="notification-popover" @fadeInOut>
          <app-notifications-badge slot="start"></app-notifications-badge>
          <ion-label>{{ 'notifications' | translate }}</ion-label>
          <ion-icon slot="end" size="small" name="notifications-outline"></ion-icon>

        </ion-item>
        <ion-popover trigger="notification-popover" cssClass="notification-popover" size="auto"
          (didDismiss)="onNotificationPopoverDismiss($event)">
          <ng-template>
            <app-notification-popover></app-notification-popover>
          </ng-template>
        </ion-popover>
        <ion-item button lines="full" *ngIf="currentOrganization$ | async as org" @fadeInOut
          (click)="editOrganisation()">
          <app-profile-image slot="start" class="org-logo" [profileImage]="{
            firstName: org['name'],
            photoURL: org.photoUrl
          }"></app-profile-image>
          <ion-label>{{ org.name }}</ion-label>
          <ion-icon size="small" slot="end" name="open-outline"></ion-icon>
        </ion-item>
        <ion-item button lines="full" (click)="logout()">
          <app-profile-image slot="start" [profileImage]="{
            firstName: (currentUser$ | async)?.firstName,
            photoURL:(firebaseUser$ | async)?.photoURL
          }"></app-profile-image>
          <ion-label *ngIf="currentUser$ | async as currentUser">
            {{currentUser.firstName}} {{currentUser.lastName}}
          </ion-label>
          <ion-icon size="small" slot="end" name="exit-outline"></ion-icon>
        </ion-item>
        <ion-item button (click)="toggleSideMenuExpand()" class="expand-toggle">
          <ion-icon slot="start" name="chevron-forward"></ion-icon>
        </ion-item>
      </ion-footer>
    </ion-menu>


    <!-- the main content -->
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
