import { Injector } from '@angular/core';

/**
 * This class will wrap the angular DI injector,
 * It will help us hook into the DI system outside the context of angular, for example in the scanner decorator
 */
export default class InjectorWrapper {
  private static injector: Injector;

  static setInjector(injector: Injector) {
    this.injector = injector;
  }

  static getInjector(): Injector {
    return this.injector;
  }
}
