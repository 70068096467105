<ion-list class="ion-no-padding" *ngIf=" notificationsToShow$ | async as  notifications">
  <ion-item *ngFor="let notification of notifications; let index = index" (click)="openNotification(notification)" lines="full" button detail>
    <ion-label>
      <h2>
        {{ notification.title }}
      </h2>
      <p>
        {{ notification.description }}

      </p>
      <ion-note>{{notification.createdAt | date: 'medium'}}</ion-note>
    </ion-label>
  </ion-item>
  <ion-item (click)="showMore()" *ngIf="showShowMoreButton$ | async" [button]="true" [detail]="true">
    {{ 'notifications.show.more' | translate }}
  </ion-item>
</ion-list>
