import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValMessagesDirective } from './val-messages.directive';
import { ValMessageDirective } from './val-message.directive';
import { MessagesConfiguration } from './val-messages-config';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ValMessagesDirective, ValMessageDirective],
  exports: [ValMessagesDirective, ValMessageDirective],
  providers: [{
    provide: MessagesConfiguration,
    useValue: new MessagesConfiguration()
  }]
})
export class ValMessagesModule {
  configure(config: MessagesConfiguration) {
    return {
      ngModule: ValMessagesModule,
      providers: [{
        provide: MessagesConfiguration,
        useValue: config
      }]
    };
  }
}
