import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Observable, from } from 'rxjs';

class TranslationLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<Record<string, string>> {
      const translationPromise: Promise<Record<string, string>> = import(`../assets/i18n/${lang}.json`);

      const translationObservable = from(translationPromise);

      return translationObservable;
  }
}

/**
 * Default loader according to ngx-translate/core
 * @see https://github.com/ngx-translate/core#configuration
 */
const defaultTranslateLoader = {
  provide: TranslateLoader,
  useClass: TranslationLoader,
  deps: [HttpClient]
};

export const ConfiguredTranslateModule = TranslateModule.forRoot({
  loader: defaultTranslateLoader,
  useDefaultLang: false
});
