import { Injectable } from '@angular/core';
import { RemoteConfig, fetchAndActivate, getStringChanges } from '@angular/fire/remote-config';
import { Observable, combineLatest, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

/** See which flags we have in firebase https://console.firebase.google.com/project/me-fit-d7a18/config */
const featureNameMap = {
  'homeScreenSettings': 'homeScreenSettingsOrgIds',
  'workoutListBulkActions': 'workoutListBulkActionsOrgIds',
  'tabletTrainingMode': 'tabletTrainingModeOrgIds',
} as const;

@Injectable({
  providedIn: 'root',
})
export class FeatureFlipService {

  calendarEnabled$ = of(true);



  constructor(private $auth: AuthService, private remoteConfig: RemoteConfig) {
    this.configureRemoteConfig();
  }

  private configureRemoteConfig() {
    this.remoteConfig.defaultConfig = environment.firebaseRemoteConfig.defaultConfig;

    // 12 hours, change to a lower value during development
    this.remoteConfig.settings.minimumFetchIntervalMillis = environment.firebaseRemoteConfig.minimumFetchIntervalMillis;

    fetchAndActivate(this.remoteConfig);
  }

  isFeatureEnabled(featureName: keyof typeof featureNameMap): Observable<boolean> {
    return combineLatest([
      this.$auth.organization$,
      getStringChanges(this.remoteConfig, featureNameMap[featureName]).pipe(
        map(stringValue => {
          let orgIds: string[] = [];
          try {
            orgIds = JSON.parse(stringValue)
          } catch {}

          return orgIds;
        }),
        tap((homeScreenSettingsOrgIds) => console.log(`[isFeatureEnabled] remote config ${featureName}`, homeScreenSettingsOrgIds)),
      )
    ]).pipe(
      map(([organization, orgIds]) => {
        if (!organization) {
          return false;
        }

        return orgIds.includes(organization.id);
      }
    ));
  }
}
