import { LoadingController, ToastController, ToastOptions } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import InjectorWrapper from "./injector-wrapper";
import { TranslationKey } from "../typings/english-translation-keys";
export interface FeedbackParams {
  i18nSuccessKey?: TranslationKey;
  i18nFailKey: TranslationKey;
  i18nLoadingMessage?: TranslationKey;
  /** Loading time in milliseconds. Defaults to 3000 */
  loadingTimer?: number;

  /** This will default to true */
  showLoader?: boolean;
}

export function Feedback(feedbackParams: FeedbackParams) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value;

    const showLoader = feedbackParams.showLoader === undefined ? true : feedbackParams.showLoader;

    descriptor.value = async function() {
      const $translate = InjectorWrapper.getInjector().get(TranslateService);

      const loadingCtrl = InjectorWrapper.getInjector().get(LoadingController);

      const loader = await loadingCtrl.create({
        message: $translate.instant(feedbackParams.i18nLoadingMessage ?? 'loading'),
      });

      try {
        if ( showLoader ) {
          loader.present();
        }

        const returnValue = await method.apply(this, arguments);

        // If we have a success key, we will set its value to the toast message
        if (feedbackParams.i18nSuccessKey) {
          showSuccessToast({
            message:  $translate.instant(feedbackParams.i18nSuccessKey),
            duration: feedbackParams.loadingTimer,
          });
        }

        return returnValue;
      } catch (error) {
        console.error(`[${feedbackParams.i18nFailKey}] Error`, error);

        showErrorToast({
          message: $translate.instant(feedbackParams.i18nFailKey),
          duration: feedbackParams.loadingTimer
        })

        throw error;
      } finally {
        if ( showLoader ) {
          loader.dismiss();
        }
      }
    }
  };
}

export const showErrorToast = async (opts: Partial<ToastOptions>) => {
  const toastCtrl = InjectorWrapper.getInjector().get(ToastController);

  const toast = await toastCtrl.create({
    ...opts,
    duration: opts.duration || 5000,
    icon: 'close-circle-outline',
    cssClass: 'toast-danger',

  });

  toast.present();

  return toast;
}

export const showSuccessToast = async (opts: Partial<ToastOptions>) => {
  const $translate = InjectorWrapper.getInjector().get(TranslateService);
  const toastCtrl = InjectorWrapper.getInjector().get(ToastController);

  const toast = await toastCtrl.create({
    ...opts,
    duration: opts.duration || 5000,
    icon: 'checkmark-circle-outline',
    cssClass: 'toast-success',
    buttons: [{
      handler: () => toast.dismiss(),
      text: $translate.instant('action.ok')
    }]
  });

  toast.present();

  return toast;
}
