import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/modules/shared.module';
import { OnboardingStartPage } from './onboarding-start/onboarding-start.page';
import { OnboardingStatusPage } from './onboarding-status/onboarding-status.page';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    FormsModule
  ],
  exports: [OnboardingStartPage, OnboardingStatusPage],
  declarations: [OnboardingStartPage, OnboardingStatusPage],
})
export class OnboardingModule { }
