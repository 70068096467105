import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridThemeDirective } from './ag-grid-theme.directive';
import { ImageFadeInDirective } from './image-fade-in.directive';
import { ImgFadeInDirective } from './img-fade-in.directive';
import { IonDateTimeFirstDayOfWeekDirective } from './ion-datetime-first-day-of-week.directive';
import { NumbersOnly } from './number-only.directive';
import { SuperAdminOnlyDirective } from './super-admin-only.directive';
import { CopyToClipboardDirective } from './copy-to-clipboard.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [AgGridThemeDirective, NumbersOnly, SuperAdminOnlyDirective, ImageFadeInDirective, IonDateTimeFirstDayOfWeekDirective, ImgFadeInDirective, CopyToClipboardDirective],
  declarations: [AgGridThemeDirective, NumbersOnly, SuperAdminOnlyDirective, ImageFadeInDirective, IonDateTimeFirstDayOfWeekDirective, ImgFadeInDirective, CopyToClipboardDirective]
})
export class DirectivesModule { }
