import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Auth } from '@angular/fire/auth';
import { map, tap } from 'rxjs/operators';
import { AuthGuardService } from './auth-guard.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService implements CanActivate {
  constructor(private router: Router, private $auth: AuthService) {}

    canActivate(next: ActivatedRouteSnapshot, route: RouterStateSnapshot) {
      return this.$auth.getLoggedInState().pipe(
        tap( isLoggedIn => {
          if (isLoggedIn) {
            console.log('[LoginGuardService] isLoggedIn=true, navigating to root page');
            this.router.navigate(['']);
          } else {
            console.log('[LoginGuardService] isLoggedIn=false, not doing anything and staying on login page');
          }
        }),
        map( isLoggedIn => !isLoggedIn ),
      );
    }
}
