import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import * as Sentry from "@sentry/angular";
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ComponentsModule } from './modules/components/components.module';
import { NgxIntlModule } from './modules/ngx-intl/ngx-intl.module';
import { AppRoutingModule } from './routing/app-routing.module';
import CustomRouterReuseStrategy from './routing/custom-router-reuse-strategy';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { ConfiguredTranslateModule } from './translate';


@NgModule({
  declarations: [AppComponent],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    provideMessaging(() => getMessaging()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideAnalytics(() => getAnalytics()),
    provideFirestore(() => getFirestore()),
    ConfiguredTranslateModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ComponentsModule,
    NgxIntlModule.forRoot({ defaultLocale: 'en' })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomRouterReuseStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
