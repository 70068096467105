import { NgModule } from "@angular/core";
import { FusejsPipe } from "./fusejs.pipe";
import { FusejsService } from "./fusejs.service";

@NgModule({
  imports: [

  ],
  declarations: [
    FusejsPipe
  ],
  exports: [FusejsPipe],
  providers: []
})
export class FuseJsModule {}
