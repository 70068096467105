import { Component, Input } from '@angular/core';

@Component({
  selector: 'me-svg-progress',
  templateUrl: './svg-progress.component.html',
  styleUrls: ['./svg-progress.component.css']
})
export class SvgProgressComponent {

  @Input()
  radius = 52;

  @Input() stroke = 4;

  @Input()
  progress: number;

  @Input()
  text: string;

  public get circumference() {
    return this.radius * 2 * Math.PI;;
  }

  public get strokeDasharray(): string {
    return `${this.circumference}px ${this.circumference}px`;
  }

  public get strokeDashoffset(): number {
    return this.circumference - this.progress / 100 * this.circumference;;
  }

  public get side() {
    return (this.radius + (this.stroke * 2)) * 2;
  }

}
