import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";


export interface SentryOnUserChange {
  userEmail: string;
  userId: string;
  isAdmin: boolean;
  organizationId?: string;
  organizationName?: string;
}

@Injectable({
  providedIn: "root"
})
export class SentryService {
  onUserChange({organizationId, organizationName, userEmail, isAdmin, userId}: SentryOnUserChange) {
    Sentry.setUser({
      email: userEmail,
      id: userId
    });

    Sentry.setTags({
      currentOrganizationId: organizationId,
      currentOrganizationName: organizationName,
      isAdmin: isAdmin,
    });
  }
}
