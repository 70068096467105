import { Component, HostBinding, Input } from '@angular/core';
import { ProfileImageOpts, ProfileImageOptsNew, ProfileImageOptsOld } from 'src/app/types';

export interface ColorVariables {
  textColor: string;
  backgroundColor: string;
}

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.css']
})
export class ProfileImageComponent {

  @Input() profileImage: ProfileImageOpts;

  @HostBinding('attr.title')
  public get name(): string {
    if ((this.profileImage as ProfileImageOptsOld).firstName) {
      return (this.profileImage as ProfileImageOptsOld).firstName;
    } else {
      return ((this.profileImage as ProfileImageOptsNew).name ?? '');
    }
  }


  public get firstLetter(): string {
    return this.name?.charAt(0);
  }

  imageLoadFail = false;

  imageLoadError() {
    this.imageLoadFail = true;
  }
}
