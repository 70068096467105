import { Injectable } from "@angular/core";
import algoliasearch, { SearchClient, SearchIndex } from "algoliasearch";
import { isEmpty } from "lodash-es";

interface SortedIndexes {
  createdAt: {
    desc: SearchIndex;
    asc: SearchIndex;
  }
}

interface InitParams {
  algoliaSecuredApiKey: string;
  organizationId: string;
}

@Injectable({
  providedIn: "root"
})
export class AlgoliaService {

  private sdkInstance: SearchClient;

  private organizationId: string;

  defaultIndex: SearchIndex;

  sortedIndexes: SortedIndexes;

  indexNames = {
    default: 'ME_Fit_Exercise',
    createdAt: {
      desc: 'ME_Fit_Exercise_createdAt_descending',
      asc: 'ME_Fit_Exercise_createdAt_ascending'
    }
  }

  init({algoliaSecuredApiKey, organizationId}: InitParams) {
    this.organizationId = organizationId;
    this.sdkInstance = algoliasearch(
      'ROHEAAZM43',
      algoliaSecuredApiKey,
    );

    this.defaultIndex = this.sdkInstance.initIndex(this.indexNames.default);

    this.sortedIndexes = {
      createdAt: {
        desc: this.sdkInstance.initIndex(this.indexNames.createdAt.desc),
        asc: this.sdkInstance.initIndex(this.indexNames.createdAt.asc)
      }
    }
  }

  /** Will search through the orgs index, and the root org id index */
  searchDefaultIndex: SearchIndex['search'] = (query, requestOpts) => {

    return this.defaultIndex.search(query, {
      ...requestOpts,
      userToken: this.organizationId
    });
  }

  getObjectIdsFilter(objectIds: string[]) {
    return objectIds.map(objectId => `objectID:${objectId}`).join(' OR ');
  }
}
