import { Injectable } from "@angular/core";
import { AcceptEmployeeInviteRequest, AcceptEmployeeInviteResponse, FindAllByIdEmployeesRequest, GrantAccessToOrganizationRequest, SuccessResponse, Organization, GetExerciseFavoritesResponse } from "@me-fit-mono/typings";
import { ME_Fit } from "me-fit-typings";
import { environment } from "src/environments/environment";
import { StateManagerService } from "./state-manager.service";

export interface OrganizationEmployeeListItem extends ME_Fit.OrganizationEmployee {
  isAdmin: boolean;
}

export interface EmployeeInviteResponse {
  employee: ME_Fit.OrganizationEmployee;
  organization: Organization;
  createdByEmployee: ME_Fit.OrganizationEmployee;
  id: string;
}

export interface CreateEmployeeRequest {
  firstName: string;
  lastName: string;
  isAdmin: boolean;
}


export type AcceptInviteRequest = Pick<ME_Fit.OrganizationEmployee & {photoUrl?: string}, 'email' | 'uid' | 'id' | 'photoUrl'>;

@Injectable({
  providedIn: "root"
})
export class EmployeesStateService {
  private API_EMPLOYEES = `${environment.API_ENDPOINT}/employees`;

  list = this.$state.createState<void, OrganizationEmployeeListItem[]>({
    httpMethod: "get",
    path: this.API_EMPLOYEES,
  });

  create = this.$state.createState<CreateEmployeeRequest, ME_Fit.OrganizationEmployee>({
    httpMethod: "post",
    path: this.API_EMPLOYEES,
    onNewResponse: () => this.list.call()
  })

  get = this.$state.createState<void, ME_Fit.OrganizationEmployee>({
    httpMethod: "get",
    path: `${this.API_EMPLOYEES}/:employeeId`
  });

  edit = this.$state.createState<Partial<ME_Fit.OrganizationEmployee>, ME_Fit.OrganizationEmployee>({
    httpMethod: "patch",
    path: `${this.API_EMPLOYEES}/:employeeId`,
    onNewResponse: () => this.list.call()
  });

  delete = this.$state.createState({
    httpMethod: "delete",
    path: `${this.API_EMPLOYEES}/:employeeId`,
    onNewResponse: () => this.list.call()
  });

  getInviteByEmployeeId = this.$state.createState<void, EmployeeInviteResponse>({
    httpMethod: "get",
    path: `${this.API_EMPLOYEES}/:employeeId/invite`
  });

  getInvite = this.$state.createState<void, EmployeeInviteResponse>({
    httpMethod: "get",
    path: `${this.API_EMPLOYEES}/invite/:inviteId`,
  });

  acceptInvite = this.$state.createState<AcceptEmployeeInviteRequest, AcceptEmployeeInviteResponse>({
    httpMethod: "patch",
    path: `${this.API_EMPLOYEES}/invite/:inviteId`,
  });

  findAllById = this.$state.createState<FindAllByIdEmployeesRequest, OrganizationEmployeeListItem[]>({
    httpMethod: "post",
    path: `${this.API_EMPLOYEES}/find-all-by-id`,
  });

  grantAccessToOrganization = this.$state.createState<GrantAccessToOrganizationRequest, SuccessResponse>({
    httpMethod: "post",
    path: `${this.API_EMPLOYEES}/grant-access-to-organization`,
  });

  getExerciseFavorites = this.$state.createState<void, GetExerciseFavoritesResponse>({
    httpMethod: "get",
    path: `${this.API_EMPLOYEES}/exercises/favorites`,
  });


  constructor(private $state: StateManagerService) {}
}
