import {
  Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren
} from '@angular/core';
import { IonItem } from '@ionic/angular';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';


export interface InlineSearchItem<T = unknown> {
  img?: string;
  title: string;
  subtitle?: string;
  badge?: {
    text: string;
    color: string;
  }
  fuseJsHighlighted?: {
    title: string;
  };
  id: string;
  rawObject: T;
}

export type InlineSearchFunction = (query: string) => Promise<InlineSearchItem[]>;

@Component({
  selector: 'me-inline-search',
  templateUrl: './inline-search.component.html',
  styleUrls: ['./inline-search.component.scss']
})
export class InlineSearchComponent implements OnInit {

  @Input() searchFn: InlineSearchFunction;

  @Input() ionItemFill: string;

  @Input() searchLabel: string;

  @Input() placeholder: string;

  @Input() ionLabelPosition: string;

  /** Will be used to exclude items already selected */
  @Input() excludeItemIds: undefined | null | Set<string> = new Set<string>();

  @Input() disabled: boolean;

  @Output() itemSelected = new EventEmitter<InlineSearchItem<any>>();

  @Input() searchHelperText?: string;

  @HostListener('keydown.Escape', ['$event'])
  @HostListener('document:keydown.Escape', ['$event'])
  @HostListener('window:keydown.Escape', ['$event']) onEscapePress(event: KeyboardEvent) {
    const autoCompleteListPresent = !this.hideAutoCompleteItems$.value;
    if (event && autoCompleteListPresent) {
      event.preventDefault();
      event.stopPropagation();

      this.hideAutoCompleteItems();
    }
  }

  @HostListener('keydown.Enter', ['$event'])
  @HostListener('document:keydown.Enter', ['$event'])
  @HostListener('window:keydown.Enter', ['$event'])
  onEnterPress(event: KeyboardEvent) {
    const autoCompleteListPresent = !this.hideAutoCompleteItems$.value;

    if (event && autoCompleteListPresent) {
      // Select the focused item
      this.selectItem(this.filteredItems[this.activeSearchItemIndex]);

      event.preventDefault();
      event.stopPropagation();
    }
  };


  // If there is a click outside the component, hide the autocomplete list
  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    const autoCompleteListPresent = !this.hideAutoCompleteItems$.value;
    if (autoCompleteListPresent) {
      const target = event.target as HTMLElement;
      const isInsideComponent = this.elementRef.nativeElement.contains(target);
      if (!isInsideComponent) {
        this.hideAutoCompleteItems();
      }
    }
  }

  onBlur(event: Event) {
    // We only want to hide the autocomplete list if the user clicks outside the component, a blur caused by the
    // ion-items shouldnt result in the autocomplete list being hidden
    const autoCompleteListPresent = !this.hideAutoCompleteItems$.value;
    if (autoCompleteListPresent) {
      // https://stackoverflow.com/questions/54489244/ionblur-event-detect-the-element-that-is-receiving-the-focus
      const target = (event['detail'] as FocusEvent).relatedTarget as HTMLElement;
      const isInsideComponent = this.elementRef.nativeElement.contains(target);
      if (!isInsideComponent) {
        this.hideAutoCompleteItems();
      }
    }
  }

  @HostListener('ionFocus', ['$event'])
  onFocus() {
    if (this.filteredItems && this.searchValue) {
      this.hideAutoCompleteItems$.next(false);
    }
  }

  activeSearchItemIndex = 0;

  filteredItems: InlineSearchItem[] = [];

  loading = false;

  @ViewChildren(IonItem, { read: ElementRef }) allIonItems: QueryList<ElementRef<HTMLElement>>;

  /** All ion-items in the auto complete list */
  public get ionItems(): Array<ElementRef<HTMLElement>> {
    // Omitting the first item because its the input field ion-items
    //
    return this.allIonItems.filter((item,) => item.nativeElement.classList.contains('search-item'));
  }

  searchValue: string | null;

  hideAutoCompleteItems$ = new BehaviorSubject(true);

  constructor(private elementRef: ElementRef<HTMLElement>) { }

  selectItem(searchItem: InlineSearchItem) {
    this.itemSelected.emit(searchItem);

    this.hideAutoCompleteItems();

    this.searchValue = '';
  }

  ngOnInit() {

  }

  async searchValueChange(query: string) {
    this.searchValue = query;
    if (isEmpty(query)) {
      this.filteredItems = [];
    } else {
      this.loading = true;

      const results = await this.searchFn(query);

      this.filteredItems = results;

      this.loading = false;
    }

    this.hideAutoCompleteItems$.next(false);
  }

  ionItemArrowDown(event: Event) {
    event.preventDefault();
    if (this.activeSearchItemIndex + 1 >= this.ionItems.length) {
      this.activeSearchItemIndex = 0;
    } else {
      this.activeSearchItemIndex++;
    }

    this.scrollFocusedItemIntoView();
  }

  ionItemArrowUp(event: Event) {
    event.preventDefault();
    if (this.activeSearchItemIndex - 1 < 0) {
      this.activeSearchItemIndex = this.ionItems.length - 1;
    } else {
      this.activeSearchItemIndex--;
    }

    this.scrollFocusedItemIntoView();
  }

  private hideAutoCompleteItems() {
    this.hideAutoCompleteItems$.next(true);
    this.activeSearchItemIndex = 0;
  }

  scrollFocusedItemIntoView() {
    const focusedElement = this.ionItems[this.activeSearchItemIndex]?.nativeElement;

    if (!focusedElement) {
      return;
    }

    if (focusedElement['scrollIntoViewIfNeeded']) {
      focusedElement['scrollIntoViewIfNeeded']();
    } else {
      focusedElement.scrollIntoView();
    }

  }
}
