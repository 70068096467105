<ion-item button routerLink="onboarding/success" class="ion-no-padding" *ngIf="showMenuItem$ | async" @fadeIn>
  <me-svg-progress
    slot="start"
    [radius]="25"
    [progress]="(onboardingCheckListProgress$ | async) ?? 0"
    [text]="(onboardingCheckListText$ | async) ?? ''">
  </me-svg-progress>
  <span class="ion-padding-end">{{ 'onboarding.status.button' | translate }}</span>
  <ion-icon name="open-outline" size="small" slot="end"></ion-icon>
</ion-item>
