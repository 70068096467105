import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'me-google-sign-in-button',
  templateUrl: './google-sign-in-button.component.html',
  styleUrls: ['./google-sign-in-button.component.scss']
})
export class GoogleSignInButtonComponent implements OnInit {

  @Input() text = 'Sign In with Google';

  constructor() { }

  ngOnInit() {
  }

}
