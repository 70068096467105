import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController, PopoverOptions, ToastController } from '@ionic/angular';
import { OrganizationType } from '@me-fit-mono/typings';
import { TranslateService } from '@ngx-translate/core';
import { getAuth, GoogleAuthProvider, signInWithPopup, UserCredential } from 'firebase/auth';
import { noop } from 'lodash-es';
import { Observable } from 'rxjs';
import { Feedback } from 'src/app/decorators/feedback-decorator';
import { AuthService } from 'src/app/services/auth.service';
import { OnboardingStateService } from 'src/app/state/onboarding.state.service';

interface OrganizationTypeOption {
  value: OrganizationType;
  label$: Observable<string>;
}

@Component({
  selector: 'me-onboarding-start-page',
  templateUrl: './onboarding-start.page.html',
  styleUrls: ['./onboarding-start.page.scss'],
})
export class OnboardingStartPage implements OnInit {

  termsAndConditions = 'https://mefit.pro/terms-and-conditions';
  privacyPolicy = 'https://mefit.pro/privacy-policy';

  organizationTypeOptions: Array<OrganizationTypeOption> = [{
    label$: this.$translate.get('organization.type.personal.training'),
    value: 'personalTraining'
  }, {
    label$: this.$translate.get('organization.type.physio'),
    value: 'health'
  }, {
    label$: this.$translate.get('organization.type.sport'),
    value: 'sport'
  }]

  organizationTypeSelectPopoverOpts: Partial<PopoverOptions> = {
    animated: false,
    size: 'cover'
  }

  form = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    organizationName: ['', Validators.required],
    termsAndConditions: [false, Validators.requiredTrue],
    privacyPolicy: [false, Validators.requiredTrue],
    organizationType: ['', Validators.required]
  });

  constructor(
    private $onboardingState: OnboardingStateService,
    private $translate: TranslateService,
    private fb: FormBuilder,
    private toastCtrl: ToastController,
    private $auth: AuthService,
    private navCtrl: NavController,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    const params = this.route.snapshot.queryParams;
    this.form.patchValue({
      firstName: params?.firstName,
      lastName: params?.lastName,
      organizationName: params?.organizationName,
    });
  }

  async signUpWithGoogle() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();

      const toast = await this.toastCtrl.create({
        message: this.$translate.instant('onboarding.form.invalid'),
        duration: 3000
      });

      toast.present();

      return;
    }
    const provider = new GoogleAuthProvider();

    const auth = getAuth();

    try {
      const userCredential = await signInWithPopup(auth, provider);

      // Not catching errors on purpose, as we are already showing the error in the method itself
      const response = await this.initiateOnboarding(userCredential).catch(noop);

      if (!response || !response?.organization) {
        return;
      }

      // This will ensure that the user is logged in and the organization is set as current
      //
      this.$auth.setCurrentOrganizationId(response.organization.id);

      // Fetching current employee to make sure login guard doesnt block the user from navigating to workouts page
      await this.$auth.fetchCurrentEmployee();

      this.navCtrl.navigateForward('onboarding/success');
    } catch (error) {

      // See https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth
      const firebaseErrorsTranslationMap = {
        'auth/popup-closed-by-user': 'auth.popup.closed.by.user',
        'auth/unauthorized-domain': 'auth.unauthorized.domain',
        'auth/popup-blocked': 'auth.popup.blocked',
      }

      if (firebaseErrorsTranslationMap[error.code]) {
        this.toastCtrl.create({
          message: this.$translate.instant(firebaseErrorsTranslationMap[error.code]),
          duration: 5000
        }).then(toast => toast.present());
        return;
      }

      console.error('[onboarding] error signing up with google', error);

      this.toastCtrl.create({
        duration: 5000,
        message: this.$translate.instant('onboarding.signup.fail'),
      }).then(toast => toast.present());
    }
  }

  @Feedback({
    i18nFailKey: 'onboarding.signup.fail'
  })
  private async initiateOnboarding(userCredential: UserCredential) {
    const promise = this.$onboardingState.initiate.call({
      request: {
        email: userCredential.user.email!,
        firstName: this.form.value.firstName!,
        lastName: this.form.value.lastName!,
        organizationName: this.form.value.organizationName!,
        uid: userCredential.user.uid,
        photoUrl: userCredential.user.photoURL ? userCredential.user.photoURL : undefined,
        organizationType: this.form.value.organizationType! as OrganizationType
      }
    })

    promise.catch(error => {
      if (error instanceof HttpErrorResponse) {
        console.error('[onboarding] error calling initiate service', error.error.message);
      } else {
        console.error('[onboarding] error calling initiate service', error);
      }

    });

    return promise;
  }
}
