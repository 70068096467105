import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ArrayContainsPipe } from './array-contains.pipe';
import { ArrayTakePipe } from './array-take.pipe';
import { IsEmptyPipe } from './is-empty.pipe';
import { PersonNamePipe } from './person-name.pipe';
import { vimeoAspectRatio } from './vimeo-aspect-ratio.pipe';
import { VimeoPlayableUrl } from './vimeo-playable-url.pipe';
import { WorkoutStepDisplayPipe } from './workout-step-display.pipe';
import { SharedWorkoutsFilterPipe } from './shared-workouts-filter.pipe';
import { ArchivedFilterTextPipe } from './archived-filter-text.pipe';
import { TranslatePipe } from '@ngx-translate/core';
import { TextColorContrastPipe } from './text-color-contrast';



@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [VimeoPlayableUrl, WorkoutStepDisplayPipe, IsEmptyPipe, PersonNamePipe, ArrayTakePipe, vimeoAspectRatio, ArrayContainsPipe, SharedWorkoutsFilterPipe, ArchivedFilterTextPipe, TextColorContrastPipe],
  exports: [VimeoPlayableUrl, WorkoutStepDisplayPipe, IsEmptyPipe, PersonNamePipe, ArrayTakePipe, vimeoAspectRatio, ArrayContainsPipe, SharedWorkoutsFilterPipe, ArchivedFilterTextPipe, TextColorContrastPipe],
  providers: [TranslatePipe]
})
export class PipesModule { }
