<ng-container *ngIf="!profileImage?.photoURL; else showImage">
  {{ firstLetter }}
</ng-container>

<ng-template #showImage>
  <img *ngIf="!imageLoadFail" class="avatar" [src]="profileImage?.photoURL" (error)="imageLoadError()" />
</ng-template>

<ng-container *ngIf="imageLoadFail">
  {{ firstLetter }}
</ng-container>
