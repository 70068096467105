<ng-container *ngFor="let group of groups">
  <ion-item lines="full" [button]="true" (click)="toggleGroupOpenState(group.id)">
    <ion-avatar slot="start">
      <app-profile-image
        [profileImage]="{firstName: group.name}"
        [style.--profile-image-text-color]="group.color?.textColor"
        [style.--profile-image-background-color]="group.color?.backgroundColor">
      >
      </app-profile-image>
    </ion-avatar>
    <ion-label>
      <ion-row class="ion-align-items-center flex-no-wrap">
        {{ group.name }}
        <ng-container *ngIf="group._count?.customers as customersTotal">
          ({{ customersTotal }})
        </ng-container>
        <ion-icon name="chevron-down-outline" class="ion-margin-start rotatable"
          [class.rotate]="openedGroups.has(group.id)"></ion-icon>
      </ion-row>
    </ion-label>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="expandGroupOut(group.id, $event)"
        *ngIf="groupStateMap[group.id] == 'loaded' && !(group.customers | isEmpty) && showGroupExpandButton" @fadeInOut>
        <ion-icon slot="icon-only" name="expand-outline"></ion-icon>
      </ion-button>
      <ion-button fill="clear" (click)="removeGroup(group.id, $event)" *ngIf="showRemoveButton">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
      <ion-button fill="clear" (click)="navigateToGroup(group.id, $event)" *ngIf="showNavigationButton">
        <span class="ion-hide-sm-down">{{ 'group.details.title' | translate }}</span>

        <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-item>

  <ion-list class="ion-no-padding ion-padding-start" @slideUpDown *ngIf="openedGroups.has(group.id)">

    <ng-container *ngIf="groupStateMap[group.id] as groupState">
      <ion-row *ngIf="groupState == 'failed'" class="ion-align-items-center ion-justify-content-between ion-padding">
        <ion-text color="danger">{{ 'group.detail.load.fail' | translate }}</ion-text>
        <ion-button size="small" (click)="loadGroup(group.id)" class="retry-button">
          {{ 'action.retry' | translate }}
        </ion-button>
      </ion-row>

      <ion-row class="ion-align-items-center ion-justify-content-between ion-padding" *ngIf="groupState == 'loading'">
        <span>
          {{ 'group.detail.loading' | translate }}
        </span>
        <ion-spinner></ion-spinner>
      </ion-row>

      <p class="ion-no-margin ion-padding" *ngIf="
              groupState == 'loaded'
              && group.customers
              && (group.customers | isEmpty)
            ">
        {{ 'group.details.customers.empty' | translate }}
      </p>

      <ng-container *ngIf="groupState == 'loaded'">
        <ion-item lines="full" *ngFor="let customer of group.customers">
          <ion-avatar slot="start">
            <app-profile-image [profileImage]="{firstName: customer.firstName}">
            </app-profile-image>
          </ion-avatar>
          <ion-label>
            <ion-row class="ion-justify-content-between">
              <div>
                <div [class.strike-through]="group.organizationCustomerOverrides?.has(customer.id)">
                  {{ customer.firstName }} {{ customer.lastName }}
                </div>

                <ion-badge color="secondary" *ngIf="group.organizationCustomerOverrides?.has(customer.id)">
                  {{ 'customer.group.workout.copy' | translate }}
                </ion-badge>
              </div>
              <ion-button
                size="small"
                (click)="navigateToWorkout(group.organizationCustomerOverrides!.get(customer.id)!, $event)"
                *ngIf="showNavigationButton && group.organizationCustomerOverrides?.has(customer.id)">
                {{ 'workout.details' | translate }}
              </ion-button>
            </ion-row>
          </ion-label>
        </ion-item>
      </ng-container>
    </ng-container>



  </ion-list>


</ng-container>
