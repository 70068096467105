import { NgModule } from '@angular/core';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { FuseJsModule } from './fusejs/fusejs.module';
import { ModalsModule } from './modals/modals.module';
import { NgxIntlModule } from './ngx-intl/ngx-intl.module';
import { ValMessagesModule } from './ngx-messages/val-messages.module';
import { PipesModule } from './pipes/pipes.module';


@NgModule({
  exports: [
    TranslateModule,
    ReactiveFormsModule,
    ValMessagesModule,
    ComponentsModule,
    AgGridModule,
    DirectivesModule,
    ModalsModule,
    PipesModule,
    NgxIntlModule,
    FuseJsModule,
    ScrollingModule
  ]
})
export class SharedModule { }
