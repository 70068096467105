<div class="container">
  <ion-input
    [fill]="ionItemFill"
    [helperText]="searchHelperText"
    type="text"
    [label]="searchLabel"
    [labelPlacement]="ionLabelPosition"
    [ngModel]="searchValue"
    (ngModelChange)="searchValueChange($event)"
    [placeholder]="placeholder"
    debounce="300"
    (keydown.ArrowDown)="ionItemArrowDown($event)"
    (keydown.ArrowUp)="ionItemArrowUp($event)"
    (ionBlur)="onBlur"
    [disabled]="disabled"
  />
  <ion-spinner *ngIf="loading"></ion-spinner>
</div>

<ion-card #container *ngIf="!(hideAutoCompleteItems$ | async)" class="ion-no-margin">
  <ion-list class="ion-no-padding">
    <ng-container *ngFor="let item of filteredItems; let index = index">
      <ion-item lines="none" [button]="true" (click)="selectItem(item)"
        [class.ion-focused]="index == activeSearchItemIndex" class="search-item" tabindex="-1"
        *ngIf="!excludeItemIds?.has(item.id)" [attr.title]="item.subtitle || item.title">
        <ion-label >


          <header class="title">
            <span *ngIf="!item.fuseJsHighlighted">
              {{ item.title }}
            </span>
            <span *ngIf="item.fuseJsHighlighted" class="fusejs-highlight" [innerHTML]="item.fuseJsHighlighted.title"></span>
          </header>
          <small *ngIf="item.subtitle">{{ item.subtitle }}</small>
          <br *ngIf="item.subtitle">
          <ion-badge *ngIf="item.badge" [color]="item.badge.color">
            {{ item.badge.text }}
          </ion-badge>
        </ion-label>
      </ion-item>
    </ng-container>

  </ion-list>
</ion-card>
