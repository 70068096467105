import { Injectable } from "@angular/core";
import { BulkAssignEmployeeRequest, BulkAssignEmployeeResponse, BulkShareWorkoutWithCustomerDto, CreateCustomerRequest, CreateOrganizationCustomerNoteRquest, CustomerGroupWorkoutsResponse, DuplicateCustomerGroupWorkoutRequest, DuplicateCustomerGroupWorkoutResponse, FindAllNotesResponse, GetCustomerResponse, GetLastLoggedWorkoutForWorkoutResponse, HomeViewResponse, LoggedWorkoutResponse, UpsertLoggedWorkoutResponse, LoggedWorkoutsSummaryResponse, RemoveDuplicateCustomerGroupWorkoutRequest, SuccessResponse, UpdateCustomerRequest, UpsertLoggedWorkoutRequest, UpdateOrganizationCustomerNoteRquest, OrganizationCustomerListByIdsRequest, OrganizationCustomerListByIdsResponse, OrganizationCustomerListResponse, OrganizationCustomerWithTag, OrganizationCustomerFindFilteredRequest, OrganizationCustomerFindFilteredResponse } from "@me-fit-mono/typings";
import { isNil } from "lodash-es";
import { ME_Fit } from "me-fit-typings";
import { environment } from "src/environments/environment";
import { WorkoutShareResponse } from "../typings/me-fit-backend.typings";
import { CalendarsStateService } from "./calendars.state.service";
import { OnboardingStateService } from "./onboarding.state.service";
import { OrganizationStateService } from "./organization.state.service";
import { StateManagerService } from "./state-manager.service";

export interface ViewCustomer {
  id: string;
  fullName: string;
  updatedAt: string;
  createdAt: string;
  invitePending: boolean;
  assignedEmployee?: ME_Fit.OrganizationEmployee;
  email?: string;
  /** Only some services will return these tags */
  tags?: string[];
}

export type CustomerIdPathPart = {
  customerId: string;
}

export type CustomerLoggedWorkoutPathPart = CustomerIdPathPart & {
  loggedWorkoutId: string;
}

@Injectable({
  providedIn: "root"
})
export class CustomersStateService {

  private API_CUSTOMERS = `${environment.API_ENDPOINT}/customers`;

  list = this.$state.createState<void, OrganizationCustomerListResponse>({
    httpMethod: "get",
    path: this.API_CUSTOMERS,
  });

  findFiltered = this.$state.createState<OrganizationCustomerFindFilteredRequest, OrganizationCustomerFindFilteredResponse>({
    httpMethod: "post",
    path: `${this.API_CUSTOMERS}/filtered`,
  });

  listByIds = this.$state.createState<OrganizationCustomerListByIdsRequest, OrganizationCustomerListByIdsResponse>({
    httpMethod: "post",
    path: `${this.API_CUSTOMERS}/list-by-ids`,
  });

  get = this.$state.createState<string, GetCustomerResponse, { customerId: string }>({
    httpMethod: "get",
    path: `${this.API_CUSTOMERS}/:customerId`,
  });

  create = this.$state.createState<CreateCustomerRequest, ME_Fit.OrganizationCustomer>({
    httpMethod: "post",
    path: this.API_CUSTOMERS,
    onNewResponse: () => {
      this.reloadListPage();
      // This is needed as the onboarding status is not updated when a new customer is created
      this.$onboarding.status.call();
    }
  });

  edit = this.$state.createState<UpdateCustomerRequest, ME_Fit.OrganizationCustomer>({
    httpMethod: "patch",
    path: `${this.API_CUSTOMERS}/:customerId`,
    onNewResponse: () => this.reloadListPage()
  });

  delete = this.$state.createState<void, ME_Fit.OrganizationCustomer, { customerId: string }>({
    httpMethod: "delete",
    path: `${this.API_CUSTOMERS}/:customerId`,
    onNewResponse: (deleteState) => {
      this.reloadListPage();

      const state = this.$calendar.findAllWithinRange.state$.value;

      const shouldRefreshCalendar = Boolean(
        state.request?.filters?.find(filter => filter.type === 'customer' && filter.id === deleteState.pathParts?.customerId)
      );

      if (shouldRefreshCalendar) {
        this.$calendar.findAllWithinRange.call({
          pathParts: state.pathParts,
          request: state.request
        });
      }

      // This is needed as the onboarding status is not updated when a new customer is deleted
      this.$onboarding.status.call();
    }
  });

  upsertLoggedWorkout = this.$state.createState<UpsertLoggedWorkoutRequest, UpsertLoggedWorkoutResponse, CustomerIdPathPart>({
    httpMethod: 'patch',
    path: `${this.API_CUSTOMERS}/:customerId/logged-workouts/upsert`,
    onNewResponse: (newResponse) => {
      // This means a new workout log was created, and we need to fetch the current workout summaries
      const matchingCustomerId = this.getLoggedWorkoutsSummary.state$.value.pathParts?.customerId === newResponse.pathParts?.customerId
      if (matchingCustomerId) {
        this.getLoggedWorkoutsSummary.call({
          pathParts: this.getLoggedWorkoutsSummary.state$.value.pathParts
        });
      }
    }
  });

  deleteLoggedWorkout = this.$state.createState<void, SuccessResponse, CustomerLoggedWorkoutPathPart>({
    httpMethod: 'delete',
    path: `${this.API_CUSTOMERS}/:customerId/logged-workouts/:loggedWorkoutId`,
    onNewResponse: (newResponse) => {
      this.$organizationState.getNotificationsEvents.call();

      // This means workout log for current customer was deleted, and we need to fetch the current workout summaries
      const matchingCustomerId = this.getLoggedWorkoutsSummary.state$.value.pathParts?.customerId === newResponse.pathParts?.customerId
      if (matchingCustomerId) {
        this.getLoggedWorkoutsSummary.call({
          pathParts: this.getLoggedWorkoutsSummary.state$.value.pathParts
        });
      }

    }
  });

  getLoggedWorkoutsSummary = this.$state.createState<void, LoggedWorkoutsSummaryResponse, CustomerIdPathPart>({
    httpMethod: "get",
    path: `${this.API_CUSTOMERS}/:customerId/logged-workouts/summary`,
  });

  getLoggedWorkout = this.$state.createState<void, LoggedWorkoutResponse, CustomerLoggedWorkoutPathPart>({
    httpMethod: "get",
    path: `${this.API_CUSTOMERS}/:customerId/logged-workouts/:loggedWorkoutId`,
  });

  getLastLoggedWorkoutForWorkout = this.$state.createState<void, GetLastLoggedWorkoutForWorkoutResponse, { customerId: string, workoutId: string }>({
    httpMethod: "get",
    path: `${this.API_CUSTOMERS}/:customerId/logged-workouts/last/:workoutId`,
  });

  bulkAssignEmployee = this.$state.createState<BulkAssignEmployeeRequest, BulkAssignEmployeeResponse>({
    httpMethod: "patch",
    path: `${this.API_CUSTOMERS}/bulk/assign-employee`,
    onNewResponse: () => this.reloadListPage()
  });

  shareWorkout = this.$state.createState<void, void, { customerId: string, workoutId: string }>({
    httpMethod: "patch",
    path: `${this.API_CUSTOMERS}/:customerId/workout/:workoutId/share`,
  });

  bulkWorkoutShare = this.$state.createState<BulkShareWorkoutWithCustomerDto, WorkoutShareResponse>({
    httpMethod: "post",
    path: `${this.API_CUSTOMERS}/bulk/workout/share`,
    onNewResponse: () => this.$onboarding.status.call()
  });

  getCustomerGroupWorkouts = this.$state.createState<void, CustomerGroupWorkoutsResponse, { customerId: string }>({
    httpMethod: 'get',
    path: `${this.API_CUSTOMERS}/:customerId/group-workouts`,
  });

  duplicateCustomerGroupWorkout = this.$state.createState<DuplicateCustomerGroupWorkoutRequest, DuplicateCustomerGroupWorkoutResponse>({
    httpMethod: 'post',
    path: `${this.API_CUSTOMERS}/duplicate-group-workout`,
  });

  removeDuplicateCustomerGroupWorkout = this.$state.createState<RemoveDuplicateCustomerGroupWorkoutRequest, SuccessResponse>({
    httpMethod: 'patch',
    path: `${this.API_CUSTOMERS}/remove-duplicate-group-workout`,
    onNewResponse: () => {
      // Assumption, last fetched customer is the one we are working with
      const activeCustomerId = this.get.state$.value.pathParts?.customerId!;

      // If we are removing a duplicate workout, we know for sure the user has a contract.
      const contractId = this.get.state$.value.response?.organizationCustomerContract.id!;

      this.getCustomerGroupWorkouts.call({
        pathParts: { customerId: activeCustomerId }
      });

      this.$organizationState.getOrganizationCustomerContractWorkouts.call({
        pathParts: {
          contractId
        }
      });
    }
  });

  getHomeViewForCustomer = this.$state.createState<void, HomeViewResponse, { customerId: string }>({
    httpMethod: "get",
    path: `${this.API_CUSTOMERS}/:customerId/app-views/home`,
  });

  findAllNotes = this.$state.createState<void, FindAllNotesResponse, { customerId: string }>({
    httpMethod: "get",
    path: `${this.API_CUSTOMERS}/:customerId/notes`,
  });

  createNote = this.$state.createState<CreateOrganizationCustomerNoteRquest, SuccessResponse, { customerId: string }>({
    httpMethod: "post",
    path: `${this.API_CUSTOMERS}/:customerId/notes`,
    onNewResponse: (state) => {
      const customerId = state.pathParts!.customerId;
      // We will refetch the notes once we create a new one
      this.findAllNotes.call({
        pathParts: {
          customerId
        }
      });
    }
  });

  updateNote = this.$state.createState<UpdateOrganizationCustomerNoteRquest, SuccessResponse, { customerId: string, noteId: string }>({
    httpMethod: "patch",
    path: `${this.API_CUSTOMERS}/:customerId/notes/:noteId`,
    onNewResponse: (state) => {
      const customerId = state.pathParts!.customerId;
      // We will refetch the notes once we update one
      this.findAllNotes.call({
        pathParts: {
          customerId
        }
      });
    }
  });

  deleteNote = this.$state.createState<void, SuccessResponse, { customerId: string, noteId: string }>({
    httpMethod: "delete",
    path: `${this.API_CUSTOMERS}/:customerId/notes/:noteId`,
    onNewResponse: (state) => {
      const customerId = state.pathParts!.customerId;
      // We will refetch the notes once we delete one
      this.findAllNotes.call({
        pathParts: {
          customerId
        }
      });
    }
  });

  constructor(
    private $state: StateManagerService,
    private $calendar: CalendarsStateService,
    private $onboarding: OnboardingStateService,
    private $organizationState: OrganizationStateService
  ) { }

  getViewCustomer(meFitCustomer: ME_Fit.OrganizationCustomer | OrganizationCustomerWithTag): ViewCustomer {
    return {
      createdAt: meFitCustomer.createdAt as string,
      fullName: `${meFitCustomer.firstName} ${meFitCustomer.lastName}`,
      id: meFitCustomer.id,
      invitePending: isNil(meFitCustomer?.organizationCustomerContract?.userId),
      updatedAt: meFitCustomer.updatedAt as string,
      assignedEmployee: meFitCustomer.assignedEmployee,
      email: meFitCustomer.organizationCustomerContract.user?.email,
      tags: 'tags' in meFitCustomer ? meFitCustomer.tags : undefined
    }
  }

  reloadListPage() {
    this.findFiltered.call({
      // Make sure to take latest filter tags into account
      request: this.findFiltered.state$.value.request
    });
  }
}
