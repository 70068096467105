import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AlertController,
  IonModal,
  ModalController,
  NavController,
} from '@ionic/angular';
import { OnboardingCheckListCode } from '@me-fit-mono/typings';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { ME_Fit } from 'me-fit-typings';
import { Observable, map, startWith } from 'rxjs';
import { fadeInOut } from 'src/app/animations';
import { CustomerCreateModalComponent } from 'src/app/modules/modals/customer-create-modal/customer-create-modal.component';
import { OnboardingStateService } from 'src/app/state/onboarding.state.service';

interface CheckListItem {
  title: Observable<string>;
  message: Observable<string>;
  done: boolean;
  /** Backend code */
  code: OnboardingCheckListCode;
  /** If this is present, we will show the usre a button */
  button?: {
    text: Observable<string>;
    onClick: () => void;
  };
}

type CheckListItemData = {
  [key in OnboardingCheckListCode]: Pick<
    CheckListItem,
    'title' | 'message' | 'button'
  >;
};

interface ActiveAccordionModalData {
  selectedAccordionId: string;
  entryData: CheckListAccordionModalEntry;
}

interface CheckListAccordionModalEntry {
  modalTitle: Observable<string>;
  modalMessage: Observable<string>;
  accordions: AccordionEntry[];
  /** What the last next button should do */
  onFinalNextClick?: () => void;
}

/**
 * For some steps we want to show the user a list of things to do.
 */
type CheckListAccordionModalMap = {
  [key in OnboardingCheckListCode]?: CheckListAccordionModalEntry;
};

type AccordionEntry = {
  id: string;
  title: Observable<string>;
  messages: Array<Observable<string>>;
};

@Component({
  selector: 'me-onboarding-status-page',
  templateUrl: './onboarding-status.page.html',
  styleUrls: ['./onboarding-status.page.scss'],
  animations: [fadeInOut],
})
export class OnboardingStatusPage implements OnInit {
  @ViewChild('accordionsModal') accordionsModal: IonModal;

  checkListItemTranslations: CheckListItemData = {
    'workout-create': {
      title: this.$translate.get('onboarding.status.workout.create.title'),
      message: this.$translate.get('onboarding.status.workout.create.message'),
      button: {
        text: this.$translate.get('onboarding.status.workout.create.button'),
        onClick: () => {
          this.activeAccordionModalData = {
            entryData: this.workoutCreateAccordionModalData['workout-create']!,
            selectedAccordionId: this.workoutCreateAccordionModalData['workout-create']!.accordions[0].id,
          }
          this.accordionsModal.present();
        },
      },
    },
    'customer-create': {
      title: this.$translate.get('onboarding.status.customer.create.title'),
      message: this.$translate.get('onboarding.status.customer.create.message'),
      button: {
        text: this.$translate.get('onboarding.status.customer.create.button'),
        onClick: () => {
          this.openCustomerCreateModal();
        },
      },
    },
    'workout-share': {
      title: this.$translate.get('share.workout.title'),
      message: this.$translate.get('onboarding.status.workout.share.message'),
      button: {
        text: this.$translate.get('share.workout.title'),
        onClick: () => {
          this.activeAccordionModalData = {
            entryData: this.workoutCreateAccordionModalData['workout-share']!,
            selectedAccordionId: this.workoutCreateAccordionModalData['workout-share']!.accordions[0].id,
          }
          this.accordionsModal.present();
        },
      },
    },
  };

  workoutCreateAccordionModalData: CheckListAccordionModalMap = {
    'workout-create': {
      modalTitle: this.$translate.get('onboarding.status.workout.create.title'),
      modalMessage: this.$translate.get(
        'onboarding.status.workout.create.modal.text'
      ),
      onFinalNextClick: () => this.navCtrl.navigateForward('/workouts/create'),
      accordions: [
        {
          id: 'template-workout',
          title: this.$translate.get('workout.list.template.tab'),
          messages: [
            this.$translate.get('onboarding.status.accordion.template.message'),
          ],
        },
        {
          id: 'shared-workout',
          title: this.$translate.get('workout.list.shared.tab'),
          messages: [
            this.$translate.get(
              'onboarding.status.accordion.shared.workout.message.one'
            ),
            this.$translate.get(
              'onboarding.status.accordion.shared.workout.message.two'
            ),
          ],
        },
        {
          id: 'create-workout',
          title: this.$translate.get('workout.create'),
          messages: [
            this.$translate.get(
              'onboarding.status.accordion.create.workout.message'
            ),
          ],
        },
      ],
    },
    'workout-share': {
      modalTitle: this.$translate.get('share.workout.title'),
      modalMessage: this.$translate.get('onboarding.status.workout.share.modal.text'),
      accordions: [{
        id: 'template-workouts',
        title: this.$translate.get('workout.list.template.tab'),
        messages: [
          this.$translate.get('onboarding.status.share.workout.template.text.one'),
          this.$translate.get('onboarding.status.share.workout.template.text.two')
        ]
      }, {
        id: 'client-workouts',
        title: this.$translate.get('onboarding.status.share.workout.customer.title'),
        messages: [
          this.$translate.get('onboarding.status.share.workout.customer.text')
        ]
      },
      {
        id: 'group-workouts',
        title: this.$translate.get('onboarding.status.share.workout.group.title'),
        messages: [
          this.$translate.get('onboarding.status.share.workout.group.text')
        ]
      }, {
        id: 'share-workout',
        title: this.$translate.get('share.workout.title'),
        messages: [
          this.$translate.get('onboarding.status.share.workout.text.one'),
          this.$translate.get('onboarding.status.share.workout.text.two')
        ]
      }],
      onFinalNextClick: () => this.navCtrl.navigateForward('/workouts'),
    }
  };

  onboardingCheckListItems$: Observable<CheckListItem[]> =
    this.$onboardingState.status.getResponse$().pipe(
      map((response) => {
        if (!response) {
          return [];
        }

        return response.checkList.map((item) => {
          const translation = this.checkListItemTranslations[item.code];

          return {
            ...translation,
            done: item.done,
            code: item.code,
          };
        });
      })
    );

  hasResponse$ = this.$onboardingState.status.getResponse$().pipe(
    startWith(null),
    map((response) => !isNil(response))
  );

  activeAccordionModalData: ActiveAccordionModalData;

  constructor(
    private $onboardingState: OnboardingStateService,
    private $translate: TranslateService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController
  ) {}

  async ngOnInit() {}

  async openCustomerCreateModal() {
    const modal = await this.modalCtrl.create({
      component: CustomerCreateModalComponent,
    });

    await modal.present();

    const event = await modal.onDidDismiss<ME_Fit.OrganizationCustomer>();

    if (event.data?.id) {
      this.navCtrl.navigateForward(`/customers/details/${event.data.id}`);
    }
  }

  workoutCreateModalAccordionNavigate(direction: 1 | -1) {
    const activeAccordionModalIds = this.activeAccordionModalData.entryData.accordions.map(
      (accordion) => accordion.id
    );

    const currentIndex = activeAccordionModalIds.indexOf(
      this.activeAccordionModalData.selectedAccordionId
    );

    const isLastIndex =
      currentIndex === activeAccordionModalIds.length - 1;

    if (isLastIndex && direction === 1) {
      // Reset this for later usage
      this.activeAccordionModalData.selectedAccordionId = activeAccordionModalIds[0];

      this.activeAccordionModalData.entryData.onFinalNextClick?.();
      this.accordionsModal.dismiss();
    }

    const nextIndex = currentIndex + direction;

    if (nextIndex < 0 || nextIndex >= activeAccordionModalIds.length) {
      return;
    }

    this.activeAccordionModalData.selectedAccordionId = activeAccordionModalIds[nextIndex];
  }

}
