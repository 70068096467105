import { Component, OnInit } from '@angular/core';
import { isNil } from 'lodash-es';
import { Observable, filter, map, pairwise } from 'rxjs';
import { fadeIn } from 'src/app/animations';
import { OnboardingStateService } from 'src/app/state/onboarding.state.service';

@Component({
  selector: 'me-onboarding-progress-menu-item',
  templateUrl: './onboarding-progress-menu-item.component.html',
  animations: [fadeIn],
  styles: [
    `:host {
      display: block;
    }`
  ]
})
export class OnboardingProgressMenuItemComponent implements OnInit {

  showMenuItem$ = this.$onboarding.status.getResponse$().pipe(
    pairwise(),
    map(([prevResponse, nextResponse]) => {
      // This means the user updates the onboarding status, and we are about to get a response object that is filled
      // we don't want this component to flash from shown to hidden back to shown.
      if (prevResponse && isNil(nextResponse)) {
        return true;
      }

      return !isNil(nextResponse) && !nextResponse.isOnboardingComplete
    })
  );

  onboardingStatus$ = this.$onboarding.status.state$.pipe(
    map(e => e.response?.isOnboardingComplete)
  );

  onboardingCheckList$ = this.$onboarding.status.state$.pipe(
    map(e => e.response?.checkList)
  );

  onboardingCheckListProgress$: Observable<number> = this.$onboarding.status.getResponse$().pipe(
    map(res => {
      const checkList = res?.checkList ?? [];

      const progress = checkList.filter( item => item.done).length / checkList.length;

      return progress * 100;
    })
  );

  onboardingCheckListText$: Observable<string> = this.$onboarding.status.getResponse$().pipe(
    filter( res => !isNil(res)),
    map(res => {
      const checkList = res?.checkList ?? [];

      const done = checkList.filter(item => item.done).length;
      const total = checkList.length;

      return `${done}/${total}`;
    }),
  );


  constructor(private $onboarding: OnboardingStateService) { }

  ngOnInit() {
  }

}
